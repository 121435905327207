$spacing: 8px;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

#root {
  margin: 0;
  padding: 0;
  height: 100%;
}

@media (max-width: 640px) {
  html, body {
    font-size: 14px;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: bold;
}

p {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
}

.page-padding-bottom {
  padding-bottom: 5rem;
}

/* HTML5 Date Picker - Hide Clear Button */
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}

/* card media class 4/3 aspect ratio */
.media {
  height: 0;
  padding-top: 75%;
}

/* Layout Utils */
.px, .px-1 {
  padding-left: $spacing;
  padding-right: $spacing;
}

.px-2 {
  padding-left: $spacing * 2;
  padding-right: $spacing * 2;
}

.py, .py-1 {
  padding-top: $spacing;
  padding-bottom: $spacing;
}

.py-2 {
  padding-top: $spacing * 2;
  padding-bottom: $spacing * 2;
}

.p1 {
  padding: $spacing;
}

.p2 {
  padding: $spacing * 2;
}

.mb-0 {
  margin-bottom: 0;
}

.mb, .mb-1 {
  margin-bottom: $spacing;
}

.mb-2 {
  margin-bottom: $spacing * 2;
}

.mt, .mt-1 {
  margin-top: $spacing;
}

.mt-2 {
  margin-top: $spacing * 2;
}

.mx, .mx-1 {
  margin-right: $spacing;
  margin-left: $spacing;
}

.mx-2 {
  margin-right: $spacing * 2;
  margin-left: $spacing * 2;
}

.my, .my-1 {
  margin-bottom: $spacing;
  margin-top: $spacing;
}

.my-2 {
  margin-bottom: $spacing * 2;
  margin-top: $spacing * 2;
}

.mr, .mr-1 {
  margin-right: $spacing;
}

.position-fixed {
  position: fixed;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}

.row {
  display: flex;
  flex: 1 0 100%;
  flex-direction: row;
}

.row-no-basis {
  display: flex;
  flex: 1 0;
  flex-direction: row;
}

.row-no-grow {
  display: flex;
  flex: 0 0;
  flex-direction: row;
}

.column {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.column-no-grow {
  display: flex;
  flex: 0;
  flex-direction: column;
}

.flex-grow {
  flex: 1
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-space-around {
  justify-content: space-around;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.d-flex {
  display: flex;
}

@mixin center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
}

.center-container {
  @include center-container;
}

.center-container-row {
  @include center-container;
  flex-direction: row;
}

.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

/* /Layout Utils */